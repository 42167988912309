import {HTTP} from '../utils/axios'

class DocumentModel extends HTTP {
  // 列表
  index(data) {
      return this.request({
          url: 'board/documents',
          method: "GET",
          data: data
      })
  }

  // 表单
  show(id) {
    return this.request({
        url: 'board/documents/' + id,
        method: "GET"
    })
  }

  /*增加*/
  create(value) {
    return this.request({
        url: "board/documents",
        method: "POST",
        data: value,
    })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/documents/" + id,
        method: "PATCH",
        data: value,
    })
  }

  /*删除*/
  destroy(id) {
    return this.request({
        url: 'board/documents/' + id,
        method: "DELETE"
    })
  }

  /*统计信息*/
  documentsStatistics() {
    return this.request({
        url: 'board/documents/documents_statistics',
        method: "GET"
    })
  }
  
  // 更新是否推荐项
  updateIsRecommend(value, id) {
    return this.request({
      url: `board/documents/${id}/is_recommend`,
      method: "PATCH",
      data: value,
    })
  }

  // 更新是否显示
  updateVisible(value, id) {
    return this.request({
      url: `board/documents/${id}/visible`,
      method: "PATCH",
      data: value,
    })
  }

  // 获取文档 pdf 链接
  getDocPreviewFileUrl(id) {
    return this.request({
      url: `board/documents/${id}/preview_file_url`,
      method: "GET"
    })
  }

  /*批量增加*/
  batch_create(value) {
    return this.request({
        url: "board/documents/batch_create",
        method: "POST",
        data: value,
    })
  }
}

export {DocumentModel}