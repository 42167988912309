<template>
  <a-spin :spinning="loading">
    <div class="reader">
      <preview-txt v-if="docType === 'txt'" :file-url="url"></preview-txt>
      <preview-pdf v-else-if="docType !== ''" :file-url="url"></preview-pdf>
    </div>
  </a-spin>
</template>

<script>
import {DocumentModel} from "@/models/document";
import {message} from 'ant-design-vue'

const documentModel = new DocumentModel()

export default {
  components: {
    PreviewTxt: () => import('@/pages/document/list/details/preview/Txt'),
    PreviewPdf: () => import('@/pages/document/list/details/preview/Pdf'),
  },
  data() {
    return {
      loading: true,
      url: '',
      docType: '',
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    documentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      documentModel.getDocPreviewFileUrl(this.documentId).then(res => {
        if (res.code === 0) {
          this.url = res.result.url
          this.docType = res.result.doc_type
        } else {
          message.error({
            content: res.message,
            duration: 5
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.reader {
  width: 945px;
  margin: 0 auto;
}
</style>